import React from 'react';
import { Container, Row, Col, Nav, Image, Form, Button } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import logo from "../../assets/logo.png";
import { Link } from 'react-router-dom';
import { menuItems } from './MenuItems';

const Footer = () => {
    const firstHalf = menuItems.slice(0, 4); 
    const secondHalf = menuItems.slice(4);

    return (
        <footer className="bg-black text-white pt-5 pb-4" >
            <Container >
                <Row className="justify-content-between align-items-start py-3">
                    <Col xs={12} md={4} className="mb-4 mb-md-0">
                        <div className="text-center text-md-start">
                            <Image src={logo} width={"130px"} alt="VeriHealth Logo" />
                            <p className="mt-3 fw-bold fs-italic">
                                Enhancing Care Through Trust and Transparency
                            </p>
                            <p className="mb-1"><strong>Email:</strong>contactus@verihealth.ca</p>
                            <p className="mb-1"><strong>Phone:</strong> 289-296-4148</p>
                            <p className="mb-1"><strong>Address:</strong> 4551 Zimmerman Ave Niagara Falls ON L2E 3M5</p>
                        </div>
                    </Col>

                    <Col xs={12} md={4} className="text-center text-md-start mb-4 mb-md-0">
                    
                        <Row>
                           
                            <Col xs={6}>
                                <ul className="list-unstyled mt-lg-5 mt-2">
                                    {firstHalf.map((link, index) => (
                                        <li key={index} className='mb-2'>
                                            <Link to={link.href} style={{ color: "grey" }} className="text-decoration-none">
                                                {link.label}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                            <Col xs={6}>
                                <ul className="list-unstyled  mt-lg-5 mt-2">
                                    {secondHalf.map((link, index) => (
                                        <li key={index} className='mb-2'>
                                            <Link to={link.href} style={{ color: "grey" }} className="text-decoration-none">
                                                {link.label}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} md={4} >
                        <div className="mb-3 text-lg-start text-center">
                            <h5>Follow Us</h5>
                            <Nav className='justify-content-lg-start justify-content-center'>
                                <Nav.Link href="#" className="text-white ps-0"><FaFacebookF /></Nav.Link>
                                <Nav.Link href="#" className="text-white "><FaTwitter /></Nav.Link>
                                <Nav.Link href="#" className="text-white "><FaLinkedinIn /></Nav.Link>
                            </Nav>
                        </div>
                        <div className='text-lg-start text-center'>
                            <h5 className="mb-3">Subscribe to our Newsletter</h5>
                            <Form className="d-flex">
                                <Form.Control type="email" placeholder="Enter your email" className="me-2" />
                                <Button variant="primary" className='px-4 py-2 global-btn glowing-border transform-hover text-white bg-black'>Subscribe</Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Row className="border-top pt-3 mt-4">
                    <Col xs={12} md={6}>
                        <p className="small mb-0 text-md-start text-center">©️ 2024 VeriHealth. All rights reserved.</p>
                    </Col>
                    <Col xs={12} md={6} className="text-md-end text-center">
                        <Link to="/privacy-policy" className="text-white text-decoration-none mx-2">Privacy Policy</Link>
                        <Link to="/terms-of-service" className="text-white text-decoration-none mx-2">Terms of Service</Link>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
