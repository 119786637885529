import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");


    return (
        <>
            <Card className=" px-3 py-1 mb-3 rounded-3">
                <Form >
                    <Form.Group className="my-2" controlId="formBasicEmail">
                        <Form.Label className="required">Name</Form.Label>
                        <Form.Control
                            type="name"
                            name="user_name"
                            className="bg-transparent py-2"
                            placeholder="Enter your name..."
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2" controlId="formBasicEmail">
                        <Form.Label className="required">Email</Form.Label>
                        <Form.Control
                            type="email"
                            className="bg-transparent py-2"
                            placeholder="Enter your email..."
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="user_Email"
                        />
                    </Form.Group>
                    <Form.Group className="my-2" controlId="formBasicEmail">
                        <Form.Label className="required">Category</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Select a category</option>
                            <option value="1">Technical Issues</option>
                            <option value="2">Billing & Payments</option>
                            <option value="3">Product Inquiries</option>
                            <option value="3">Complaints & Feedback</option>
                            <option value="3">Account Management</option>
                            <option value="3">Policy Questions</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="my-2" controlId="formBasicEmail">
                        <Form.Label className="required">Subject</Form.Label>
                        <Form.Control
                            type="name"
                            className="bg-transparent py-2 "
                            placeholder="Enter subject..."
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            name="subject"
                        />
                    </Form.Group>
                    <Form.Group
                        className="my-2"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Form.Label className="required">Message</Form.Label>
                        <Form.Control
                            className="bg-transparent"
                            placeholder="Enter your message..."
                            as="textarea"
                            rows={4}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            name="message"
                        />
                    </Form.Group>
                    <div className="text-end my-3">
                        <Button className='px-4 py-2 global-btn glowing-border transform-hover text-black' type="submit">
                            SEND MAIL
                        </Button>
                    </div>



                </Form>
            </Card>
        </>
    );
};

export default ContactForm;