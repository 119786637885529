import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import sideimage from "../../assets/heromain.png";
const HeroSection = () => {
    return (
        <div className='hero-top bg-black align-items-center align-items-lg-end'>
            <Container className=' position-relative text-white'>
                <Row className='justify-content-between align-items-start'  data-aos="fade-up">
                    <Col xs={12} lg={6} className='hero  text-lg-start text-center'>
                        <h1 className='fw-bold mb-0' >Welcome to <span style={{ color: '#0df1eb' }}>VeriHealth</span></h1>
                        <h5 className='text-white fw-bold mt-4  mb-0'  >Enhancing Care Through Trust and Transparency</h5>
                        <p className='text-white mt-4' style={{fontSize:"18px"}}>At VeriHealth, we offer a range of comprehensive verification services tailored to meet the needs of healthcare professionals. Our streamlined process ensures that your credentials are accurately verified, giving you the confidence to showcase your qualifications with pride.</p>
                        <Button className='px-4 py-2 mt-3 global-btn glowing-border transform-hover bg-transparent' style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>Get Started</Button>
                    </Col>
                    <Col xs={12} lg={6} className='d-md-block d-none'>
                        <div className='d-flex align-items-end h-100'  >
                            <Image
                                src={sideimage}
                                alt="How it works"
                                style={{ width: '100%', height: "auto" }}
                            />
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    );
};


export default HeroSection;
