import React from 'react';
import {  Container, Row } from 'react-bootstrap';

const HowItWorks = () => {
    
    return (
        <div >
            <Container fluid data-aos="fade-up">
                <Row className=' flex-column-reverse flex-lg-row'>
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: "100%",
                            paddingTop: '56.2225%',
                            paddingBottom: 0,
                            boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
                            overflow: 'hidden',
                            borderRadius: '0px',
                            willChange: 'transform',
                        }}
                    >
                        <iframe
                            title='how-it-works'
                            loading="lazy"
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                border: 'none',
                                padding: 0,
                                margin: 0,
                            }}
                            src="https://www.canva.com/design/DAGShjHrh0s/rKnB9sKqGn_Sn-mv_D54rw/view?embed"
                            
                        ></iframe>
                    </div>
                </Row>
            </Container>

        </div>
    );
};

export default HowItWorks;
