import React from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ArticlesAndBlogsCards = ({ slug, title, content, imagePlaceholder, type, image }) => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/${type}/${slug}`);
    };

    const truncateText = (text, length) => {
        if (text.length > length) {
            return text.slice(0, length) + '...';
        }
        return text;
    };

    return (
        <>
            <Card
                className="border-0 my-3"
                style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    cursor: "pointer",
                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)"
                }}
                onClick={handleCardClick}
                 data-aos="fade-up"
            >
                <div
                    style={{
                        height: "230px",
                        width: "100%",
                        overflow: "hidden",
                    }}
                >

                    <Image src={image} width={"100%"} height={"auto"} />
                </div>
                <div className='px-3 mb-3'>
                    <h5 className="fw-semibold mt-2">{truncateText(title, 50)}</h5>
                    <p className="text-muted mt-2" style={{ fontSize: "16px" }}>
                        {truncateText(content[0], 80)}
                    </p>
                    <Button
                        className='bg-transparent border-0 px-0 fw-bold'
                        style={{ color: "gray" }}
                    >
                        Read More
                    </Button>
                </div>
            </Card>
        </>
    );
};

export default ArticlesAndBlogsCards;
