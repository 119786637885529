import PageHeading from 'components/Shared/PageHeading';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { MdOutlineVerifiedUser } from "react-icons/md";
import { RiSecurePaymentLine } from "react-icons/ri";
import { GrCertificate } from "react-icons/gr";
import { MdOutlineMonitorHeart } from "react-icons/md";
import { PiSelectionBackgroundBold } from "react-icons/pi";

const services = [
    {
        title: "Credential Verification",
        description: [
            "Verify your educational background, certifications, licenses, and other qualifications with ease.",
            // "Ensure that your credentials are up-to-date and valid for employers and patients."
        ],
        icon: <RiSecurePaymentLine size={40} color='#0df1eb' />,
    },
    {
        title: "License Verification",
        description: [
            "Verify your professional licenses to ensure compliance with regulatory requirements.",
            // "Stay informed about any updates or changes to your licensing status."
        ],
        icon: <MdOutlineVerifiedUser size={40} color='#0df1eb' />,
    },
    {
        title: "Certification Verification",
        description: [
            "Verify your specialized certifications and credentials to demonstrate your expertise in your field.",
            // "Highlight your commitment to ongoing professional development and education."
        ],
        icon: <GrCertificate size={40} color='#0df1eb' />,
    },
    {
        title: "Background Checks Verification",
        description: [
            "Facilitate the completion of police checks by staff members to verify their background.",
            // "Gather and keep Police vulnerable sector check documents to boost credibility and trustworthiness."
        ],
        icon: <PiSelectionBackgroundBold size={40} color='#0df1eb' />,
    },
    {
        title: "Ongoing Monitoring",
        description: [
            "Stay informed about any changes or updates to your credentials through our ongoing monitoring services.",
            // "Ensure that your qualifications remain valid and up-to-date at all times."
        ],
        icon: <MdOutlineMonitorHeart size={40} color='#0df1eb' />,
    }
];

const Services = () => {
    return (
        <>
            <div className='linesbg' style={{ backgroundColor: "#f7f4f4" }} >
                <Container className=' py-3' data-aos="fade-up">
                    <PageHeading sub heading={"What We Offer"} text={"At VeriHealth, we make credential verification easy for healthcare professionals. Join VeriHealth today for a seamless credential verification process that boosts your credibility in the healthcare industry! "} />
                    <Row className='mb-3 justify-content-center'>
                        {services.map((service, index) => (
                            <Col xs={12} lg={4} key={index}>
                                <Card
                                data-aos="fade-up"
                                    className='px-2 py-3 border-0 my-lg-4 my-2'
                                    style={{ borderRadius: "20px", transition: "transform 0.3s ease, box-shadow 0.3s ease" }}
                                >
                                    <Row>

                                        <Col xs={3} className='text-center'>
                                            <div >
                                                <div className='m-auto d-flex align-items-center justify-content-center mb-3' style={{ height: "60px", width: "60px", borderRadius: "60px", background: "#000000" }}>
                                                    <span className='flipicon'>{service.icon}</span>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col xs={9}>
                                            <div className='text-start'>
                                                <h4 className='fw-bold' style={{ fontSize: "18px" }}>{service.title}</h4>
                                            </div>
                                            <ul className='text-muted ps-0 pe-4 mb-0' style={{ minHeight: "100px",listStyleType:"none" }}>
                                                {service.description.map((desc, i) => (
                                                    <li key={i} className='mt-2' style={{ fontSize: "16px" }}>{desc}</li>
                                                ))}
                                            </ul>
                                        </Col>
                                    </Row>


                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Services;
