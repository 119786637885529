import PageHeading from 'components/Shared/PageHeading';
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import sideImage from '../assets/about-home.png';
import Banner from 'components/Shared/Banner';
import MissionAndVision from 'components/About/MissionAndVision';
import mission from "../assets/mission.jpg"
import vision from "../assets/vision.jpg"

const About = () => {

    const services = [
        {
            img: mission,
            title: "Mission Statement",
            description: "To provide a comprehensive and efficient platform for verifying and maintaining the credentials of healthcare professionals, ensuring transparency, credibility, and compliance within the industry."

        },
        {
            img: vision,
            title: "Vision Statement",
            description: "To be the trusted partner in credential verification, empowering healthcare organizations and professionals to uphold the highest standards of integrity, professionalism, and quality of care for the benefit of patients and the healthcare community."
        },


    ];
    return (
        <>
        <PageHeading heading={"About Us"} />
            <Container data-aos="fade-up">
                
                <Row
                    className={`align-items-center py-5 `}
                >
                    <Col xs={12} lg={6} className='d-flex align-items-center'>
                        <div>
                            <div className='mb-3'>
                                <h2 className='fw-semibold mt-2'>About VeriHealth</h2>
                                <p className="fs-5 fw-semibold " style={{fontSize:"18px"}}>
                                    Enhancing Care Through Trust and Transparency
                                </p>
                            </div>
                            <p className='text-muted' style={{fontSize:"18px"}}>Welcome to VeriHealth, a leading company dedicated to verifying the credentials of healthcare staff. Our mission is to provide a seamless and efficient platform for healthcare professionals to showcase their qualifications and expertise. By ensuring that healthcare staff have valid and up-to-date credentials, we aim to enhance patient safety and trust in the healthcare industry.</p>
                            <p className='text-muted' style={{fontSize:"18px"}}>
                                At VeriHealth, we believe in the importance of accuracy and transparency in verifying healthcare credentials. Our team is committed to maintaining the highest standards of quality and reliability in our verification process. We strive to be a trusted partner for healthcare organizations and professionals, providing them with the confidence they need to deliver exceptional care to patients.
                            </p>
                            <p className=" fw-bold" style={{fontSize:"16px"}}>
                                “ Thank you for choosing VeriHealth as your trusted partner in credential verification ”
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} lg={6} className='text-center'>
                        <Image src={sideImage} width={'70%'} style={{ borderRadius: "30px" }} />
                    </Col>
                </Row>
               <MissionAndVision services={services}/>
            </Container>
            <Banner/>
        </>
    );
};

export default About;
